<template>
  <div>
    <Modal modal-class="slide-up" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('setting.create area')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
            <div class="modal-body">
              <ValidationObserver ref="create_code" v-slot="{ passes, valid, validated }">
                <AppInput hidden name="create_code_force_reject" v-model="validate.create_code_force_reject"></AppInput>
                <AppInput name="code" cleave-regex="[^a-zA-Z]" :label="$t('common.area code')"
                          type="string-uppercase-length:2"
                          placeholder="アルファベット２文字以内"
                          rules="required|booking_code:@create_code_force_reject"
                          v-model="entryToSave.code"/>
              </ValidationObserver>
              <AppInput name="name" :label="$t('common.area name')" type="string" rules="required"
                        v-model="entryToSave.name"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('setting.update area')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)">
            <div class="modal-body">
              <ValidationObserver ref="update_code" v-slot="{ passes, valid, validated }">
                <AppInput hidden name="update_code_force_reject"
                          v-model="validate.update_code_force_reject"></AppInput>
                <AppInput name="code" cleave-regex="[^a-zA-Z]" :label="$t('common.area code')"
                          type="string-uppercase-length:2"
                          placeholder="アルファベット２文字以内"
                          rules="required|booking_code:@update_code_force_reject"
                          v-model="entryToEdit.code"/>
              </ValidationObserver>
              <AppInput name="name" :label="$t('common.area name')" type="string" rules="required"
                        v-model="entryToEdit.name"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      validate: {
        create_code_force_reject: false,
        update_code_force_reject: false,
      },
      tableName: this.$t('setting.area list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.AREA_LIST,
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.AREA_DELETE,
        }
      },
      actions: {
        editEntry: this.editEntry,
        createEntry: this.createEntry,
        deleteEntry: true
      },
      tools: {
        search: {
          filters: []
        }
      },
      entryToSave: {},
      entryToEdit: {},
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {name: 'code', label: this.$t('common.area code')},
        {name: 'name', label: this.$t('common.area name')},
        {name: 'creator', label: this.$t('common.creator'), listShow: true},
        {
          name: 'created_at',
          label: this.$t('common.created at'),
          class: 'text-center min-w-60 max-w-60',
          listShow: true
        },
      ]
    }
  },
  watch: {
    'entryToSave.code': {
      handler: function (val, oldVal) {
        if (val && val.length) {
          this.validate.create_code_force_reject = !this.validateAreaCode(this.entryToSave);
          setTimeout(() => this.$refs.create_code.validate(), 100)
        }
      },
      deep: true
    },
    'entryToEdit.code': {
      handler: function (val, oldVal) {
        if (val && val.length) {
          this.validate.update_code_force_reject = !this.validateAreaCode(this.entryToEdit);
          setTimeout(() => this.$refs.update_code.validate(), 100)
        }
      }
    },
    deep: true
  },
  components: {BasicTable},
  methods: {
    /* CREATE entry start */
    clearEntryToSave() {
      this.entryToSave = {}
      this.$refs.form_create_entry.reset()
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
    },
    async saveEntry() {
      const {code, name} = this.entryToSave
      const res = await this.$request.post(this.$consts.API.ADMIN.AREA_SAVE, {
        code, name
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {};
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    /* CREATE entry end */

    /* UPDATE entry start */
    editEntry(entry) {
      this.entryToEdit = _.clone(entry)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      const {code, name} = this.entryToEdit
      const res = await this.$request.patch(this.$consts.API.ADMIN.AREA_EDIT(this.entryToEdit.id), {
        code, name
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    clearEntryToEdit() {
      this.$refs.form_update_entry.reset()
    },
    validateAreaCode(entry) {
      var result = true;
      _.forEach(this.$refs.table.entriesRes.data.relateData.codes, area => {
        if (entry.id) {
          if (area.id != entry.id && entry.code == area.code) {
            result = false;
            return false;
          }
        } else {
          if (entry.code == area.code) {
            result = false;
            return false;
          }
        }
      })
      return result;
    }

    /* UPDATE entry end */
  }
}
</script>
